<template>
    <zw-sidebar @shown="shown" :title="$t('jobs.title.job_info')">
        <div v-if="!loading" class="px-3 py-2">
            <b-card
                border-variant="primary"
                align="center"
            >
                <b-card-text>
                    <b-row>
                        <b-col class="text-right" cols="4"><strong>{{ $t('jobs.column.id') }}:</strong></b-col>
                        <b-col class="text-left" cols="8">{{ result.job_group.id }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="4"><strong>{{ $t('jobs.column.group_id') }}:</strong></b-col>
                        <b-col class="text-left" cols="8">{{ result.job_group.group }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="4"><strong>{{ $t('jobs.column.status') }}:</strong></b-col>
                        <b-col class="text-left" cols="8">{{ result.job_group.status }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="4"><strong>{{ $t('jobs.column.type') }}:</strong></b-col>
                        <b-col class="text-left" cols="8">{{ result.job_group.type }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="4"><strong>{{ $t('jobs.column.jobs_count') }}:</strong></b-col>
                        <b-col class="text-left" cols="8">{{ result.job_group.jobs_executed }} /
                            {{ result.job_group.jobs_count }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="4"><strong>{{ $t('jobs.column.actions_count') }}:</strong>
                        </b-col>
                        <b-col class="text-left" cols="8">{{ result.job_group.actions_success }} /
                            {{ result.job_group.actions_count }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="4"><strong>{{ $t('jobs.column.created_at') }}:</strong></b-col>
                        <b-col class="text-left" cols="8">
                            {{ result.job_group.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right" cols="4"><strong>{{ $t('jobs.column.updated_at') }}:</strong></b-col>
                        <b-col class="text-left" cols="8">
                            {{ result.job_group.updated_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <div class="accordion" role="tablist">
                <b-card no-body class="m-0"
                        v-if="typeof payload.job_id == 'undefined' || job.id == payload.job_id"
                        v-for="job in result.jobs"
                        :key="job.id">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle="'accordion-' + job.id"
                                  :variant="getVariant(job.status)"
                                  class="text-left"
                        >
                            ID: {{ job.id }} ({{ job.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }})
                            <div>
                                {{ (job.error || '')|limit(150) }}
                                {{ (job.message || '')|limit(150) }}
                            </div>
                        </b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-'+job.id" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-row>
                                <b-col class="text-right" cols="3"><strong>{{ $t('jobs.column.id') }}:</strong>
                                </b-col>
                                <b-col class="text-left" cols="9">
                                    {{ job.id }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-right" cols="3"><strong>{{ $t('jobs.column.status') }}:</strong>
                                </b-col>
                                <b-col class="text-left" cols="9">
                                    {{ job.status }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-right" cols="3"><strong>{{ $t('jobs.column.created_at') }}:</strong>
                                </b-col>
                                <b-col class="text-left" cols="9">
                                    {{ job.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-right" cols="3"><strong>{{ $t('jobs.column.updated_at') }}:</strong>
                                </b-col>
                                <b-col class="text-left" cols="9">
                                    {{ job.updated_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-right" cols="3"><strong>{{ $t('jobs.column.message') }}:</strong>
                                </b-col>
                                <b-col class="text-left" cols="9">
                                    <b-textarea class="mb-2" v-model="job.message"></b-textarea>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-right" cols="3"><strong>{{ $t('jobs.column.error') }}:</strong>
                                </b-col>
                                <b-col class="text-left" cols="9">
                                    <b-textarea class="mb-2" v-model="job.error"></b-textarea>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-right" cols="3"><strong>{{ $t('jobs.column.payload') }}:</strong>
                                </b-col>
                                <b-col class="text-left" cols="9">
                                    <b-textarea class="mb-2" v-model="job.data"></b-textarea>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'JobInfoModal',
    data() {
        return {
            loading: true,
            payload: {},
            result: null,
        }
    },
    methods: {
        ...mapGetters('Jobs', ['getJobsInfo']),
        shown() {
            this.$store.dispatch('Jobs/fetchJobInfo', this.payload.job_group)
                .then(() => {
                    this.result = this.getJobsInfo()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getVariant(status) {
            if (status == 'done') {
                return 'success'
            }
            if (status == 'failed') {
                return 'danger'
            }
            return 'info'
        }
    },
}
</script>